import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Fayetteville GA Web Design - Noprofit OMG - Websuasion"
        ogDescription="Are you looking for a professional web design serving Fayetteville, GA? See how we enabled One More Generation to achieve their goals."
        image="/images/case-studies/one-more-generation.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Fayetteville GA Web Design</h1>
						<h3>A Case Study of One More Generation</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled One More Generation to achieve their non-profit business goals by providing website design, photography, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web designer serving the Fayetteville, GA area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/one-more-generation.jpg" className="rounded-image" alt="Fayetteville GA Web Design - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/one-more-generation-logo.jpg" alt="Fayetteville GA Web Design - Websuasion" />
							</p>
			        <p>
								One More Generation (OMG) was founded in 2009 by 8 year old Carter Ries and 7 year old Olivia Ries. Carter and Olivia started their own nonprofit in an effort to help educate children and adults about the plight of endangered species with the intention to preserve all species for at least One More Generation … and beyond.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Ryan and his team have been amazing to work with. As a small nonprofit organization with a global reach, we needed someone who could help us realize our vision and think two steps ahead of our dreams and the folks at Websuasion did exactly that. Ryan has been there for us during our first five years and we look forward to seeing what his team can do for us in the next. Best money we have ever spent.</Blockquote>
							<p>
								- Jim Ries, President
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/one-more-generation-jim-ries.jpg" className="rounded-square" alt="Fayetteville GA Web Design - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Website</li>
			        <li>E-commerce</li>
							<li>Web application</li>
			        <li>Email marketing</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Wordpress install and theme modification</li>
			        <li>Shopify cart integration</li>
							<li>Interactive pledge campaign web application</li>
			        <li>Mailchimp email template design and configuration</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>One More Generation Website</h2>
							<p>
								In 2009, we began a collaborative partnership with One More Generation to create a more impactful, but easy to manage website designed to highlight various conservation programs, endangered species campaigns, and youth empowerment missions. To meet their needs on a tight budget, we used a stock Wordpress theme which we modified to deal with their specific content needs including articles, videos, photos, and integrated social media functionality. We also helped to set up a Shopify e-commerce system to handle donations and special product offerings.
							</p>
							<p>
								<img src="/images/case-studies/one-more-generation-site.jpg" alt="Fayetteville GA Web Design - Websuasion" />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Campaign Landing Site and Web Portal</h2>
							<p>
								In 2016, as part of the expansion of the environmental conservation program, OMG turned their focus to the reduction of plastic straw usage and was in need of a separate pledge campaign site. Our team designed and programmed the One Less Straw campaign web app to include graphic design of several of the campaign assets, video, animation, and a Google map geographically displaying pledgees, businesses, partners, and participating schools across the world. We also designed a simple administration portal so OMG staff could manage pledges. We additionally set up the pledge submission to integrate with Mailchimp mailing lists that provide special incentive offers to pledge participants.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/onelessstraw.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>On a continual basis, we provide consultation on marketing and advertisement for social media, as well as, technical support.</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
